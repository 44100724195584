import React, { render } from 'react';
// @ts-ignore
import Comments from 'common/js/comments';
// @ts-ignore
import { CommentsAreaT } from 'common/js/comments/store';

const store = window['commentsArea'] as CommentsAreaT;
const commentsElement = document.getElementById('comments');

if (commentsElement) {
    render(
        <Comments
            comments={store.comments}
            isEnabled={store.isEnabled}
        />,
        commentsElement,
    );
}

export {};
