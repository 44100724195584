import React, { useRef, useState } from 'preact/compat';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import SVGIcon from '../../components/SVGIcon';
import { useReplyForm } from '../store';
import { AlertTypesE, AlertTypeT } from '../../components/Alert';
import AlertHn, {
    AlertTypesE as AlertHnTypesE,
    AlertTypeT as AlertHnTypeT,
} from '../../components/AlertHn';
import { isEmail, checkInputValueLength } from '../../utils/validate';
import {
    HCAPTCHA_SITE_KEY,
    COMMENTS_TERMS_LINK,
    COMMENTS_PRIVACY_LINK,
    COMMENTS_API_LINK,
} from '../../constants';

const MIN_LENGTH = 3;
const MAX_LENGTH_EMAIL = 100;
const MAX_LENGTH_NAME = 255;
const MAX_LENGTH_CONTENT = 65534;

type PropsT = {
    useCustomTemplate?: boolean;
};

const CommentForm = ({ useCustomTemplate = false }: PropsT) => {
    const [replyToCommentId, setReplyToCommentId] = useReplyForm((store) => [
        store.replyToCommentId,
        store.setReplyToCommentId,
    ]);
    const [submitStatusIsInProgress, setSubmitStatus] = useState(false);
    const EmptyAlertValues = {
        text: '',
        type: AlertTypesE.Success,
    } as const;
    const EmptyAlertValuesHn = {
        text: '',
        type: AlertHnTypesE.Success,
    } as const;
    const [alertData, setAlertData] = useState<{
        text: string;
        type: AlertTypeT;
    }>(EmptyAlertValues);
    const [alertDataHn, setAlertDataHn] = useState<{
        text: string;
        type: AlertHnTypeT;
    }>(EmptyAlertValuesHn);
    const clearAlert = () => setAlertData(EmptyAlertValues);
    const clearAlertHn = () => setAlertDataHn(EmptyAlertValuesHn);
    const isUsedForReply = replyToCommentId !== null;
    const hCaptchaHandlers: { [key: string]: any } = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleVerificationSuccess: (token) => {},
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleErrors: (err) => {},
        handleCaptchaLoad: () => {},
        handleTokenExpire: () => hCaptchaHandlers.captchaRef.current.resetCaptcha(),
        captchaRef: useRef(null),
    };

    const checkInputLength = (e: Event) => {
        const input = e.target as HTMLInputElement;
        const value = input.value.trim();

        input.setCustomValidity('');

        if (!checkInputValueLength(value, MIN_LENGTH)) {
            input.setCustomValidity('At least 3 characters long');
        } else if (input.type === 'email' && !isEmail(value)) {
            input.setCustomValidity('Email must be valid');
        }

        input.reportValidity();
    };

    const isFormValid = (form: HTMLFormElement) => {
        // @ts-ignore
        if ('hcaptcha' in window && !window.hcaptcha?.getResponse()) {
            hCaptchaHandlers.captchaRef.current.execute();

            return false;
        }

        return form.checkValidity();
    };

    const showMessage = (data, isError = false) => {
        if (isError) {
            setAlertData({
                text:
                    data.errors && data.errors.length > 0 && data.errors[0].email
                        ? `Email - ${data.errors[0].email}`
                        : 'Ooops! Something went wrong.',
                type: AlertTypesE.Error,
            });
            setAlertDataHn({
                text:
                    data.errors && data.errors.length > 0 && data.errors[0].email
                        ? 'Please fill in all fields correctly.'
                        : 'Please fill in all fields correctly.',
                type: AlertHnTypesE.Error,
            });
        } else {
            setAlertData({
                text: 'Success! Your comment is awaiting moderation.',
                type: AlertTypesE.Success,
            });
            setAlertDataHn({
                text: 'Success! Your comment is awaiting moderation.',
                type: AlertHnTypesE.Success,
            });
        }
    };

    async function sendContactForm(form: HTMLFormElement): Promise<{ data: any; status: number }> {
        const formData = new FormData(form);
        const apiEndpoint = form.action;
        // @ts-ignore
        // eslint-disable-next-line max-len
        const articleId = window.queriedObject?.type === 'article' ? window?.queriedObject.id : null;

        const formBody = {
            replyToCommentId,
            articleId,
        };

        for (const pair of formData.entries()) {
            formBody[pair[0] as string] = pair[1] as string;
        }

        return fetch(apiEndpoint, {
            method: 'POST',
            body: JSON.stringify(formBody),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response)
            .then((response) => response.json().then((json) => ({
                status: response.status,
                json,
            })))
            .then(({ status, json }) => ({ data: json, status }));
    }

    async function handleSubmit(e: Event) {
        e.preventDefault();
        const form = e.currentTarget as HTMLFormElement;

        if (isFormValid(form)) {
            clearAlert();
            clearAlertHn();
            setSubmitStatus(true);
            const result = await sendContactForm(form);
            const isErrorOnSubmit = result.status !== 201;

            setSubmitStatus(false);

            showMessage(result.data, isErrorOnSubmit);

            if (!isErrorOnSubmit) {
                form.reset();

                if (window['hcaptcha']) {
                    hCaptchaHandlers.captchaRef.current.resetCaptcha();
                }
            }
        } else {
            form.reportValidity();
        }
    }

    const customTemplate = () => (
        <div id="comments-reply" className="space space_size_l">
            <div className="display_flex display_space-between">
                <div className="heading heading_size_3">Leave a reply</div>
                {isUsedForReply && (
                    <button
                        className="link display_flex"
                        type="button"
                        onClick={() => setReplyToCommentId(null)}
                        style={{ 'align-self': 'center' }}
                    >
                        <SVGIcon iconId="mdi-close" width={24} />
                    </button>
                )}
            </div>
            <p className="space space_size_xs text text_size_small">
                Your email will not be published. All fields are required.
            </p>
            <form
                id="comment-form"
                className="space_size_vl text text_size_small comment-form__form"
                action={COMMENTS_API_LINK}
                method="POST"
                onSubmit={handleSubmit}
            >
                {alertDataHn.text && (
                    <div className="toast__comment-wrapper">
                        <AlertHn type={alertDataHn.type}>
                            {alertDataHn.text}
                        </AlertHn>
                    </div>
                )}
                <label htmlFor="comment-form-text">
                    <strong className="form-label form-label_required">Comment</strong>
                    <textarea
                        id="comment-form-text"
                        name="comment"
                        required
                        cols={45}
                        rows={8}
                        className="form-input space space_size_s"
                        minLength={MIN_LENGTH}
                        maxLength={MAX_LENGTH_CONTENT}
                        placeholder="Leave a comment"
                    />
                </label>
                <div className="space space_size_n">
                    <div className="comment-form__items-block">
                        <label className="comment-form__item" htmlFor="comment-form-name">
                            <strong className="form-label form-label_required">Name</strong>
                            <input
                                id="comment-form-name"
                                type="text"
                                name="name"
                                required
                                className="form-input space space_size_s"
                                minLength={MIN_LENGTH}
                                placeholder="Enter your name"
                            />
                        </label>
                        <label className="comment-form__item" htmlFor="comment-form-email">
                            <strong className="form-label form-label_required">Email</strong>
                            <input
                                id="comment-form-email"
                                type="email"
                                name="email"
                                required
                                className="form-input space space_size_s"
                                minLength={MIN_LENGTH}
                                placeholder="Enter your email"
                            />
                        </label>
                    </div>
                    <div className="space space_size_n">
                        <HCaptcha
                            sitekey={HCAPTCHA_SITE_KEY}
                            size="normal"
                            onVerify={hCaptchaHandlers.handleVerificationSuccess}
                            onError={hCaptchaHandlers.handleErrors}
                            onLoad={hCaptchaHandlers.handleCaptchaLoad}
                            onExpire={hCaptchaHandlers.handleTokenExpire}
                            ref={hCaptchaHandlers.captchaRef}
                        />
                    </div>
                    <label className="space space_size_n display_block" htmlFor="privacy_policy">
                        <strong className="form-label form-label_strong form-label_required">
                            Privacy Policy Agreement
                        </strong>
                        <span className="space space_size_s content display_flex text-m-regular">
                            <input
                                id="privacy_policy"
                                name="privacy_policy"
                                required
                                type="checkbox"
                                className="checkbox__input"
                            />
                            <label
                                data-css="checkbox"
                                className="checkbox__label"
                                htmlFor="privacy_policy"
                            />
                            <span className="display_flex display_flex_wrap">
                                I agree to the&nbsp;
                                <a
                                    className="link link_color_blue content__tag_unstyled"
                                    href={COMMENTS_TERMS_LINK}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms &amp; Conditions
                                </a>
                                &nbsp;and&nbsp;
                                <a
                                    className="link link_color_blue content__tag_unstyled"
                                    href={COMMENTS_PRIVACY_LINK}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                .
                            </span>
                        </span>
                    </label>
                </div>
                <div className="space space_size_l position_relative">
                    <button
                        className="button button_blue_primary button_size_wide_vl"
                        type="submit"
                        disabled={submitStatusIsInProgress}
                    >
                        {submitStatusIsInProgress && (
                            <SVGIcon
                                className="button__icon"
                                iconId="mdi-loading"
                                width={20}
                                spin
                            />
                        )}
                        Post comment
                    </button>
                </div>
            </form>
        </div>
    );

    const defaultTemplate = () => (
        <div data-css="space" className="space space_size_l" id="comments-reply">
            <div data-css="cells" className="cells cells_align_center">
                <div
                    data-css="heading"
                    className="heading heading_size_3 cells__item cells__item_width_fill"
                >
                    Leave a Reply
                </div>
                {isUsedForReply && (
                    <button
                        data-css="link display"
                        className="cells__item link display_flex"
                        type="button"
                        onClick={() => setReplyToCommentId(null)}
                    >
                        <SVGIcon iconId="mdi-close" width={24} />
                    </button>
                )}
            </div>
            <p data-css="space text" className="space space_size_xs text text_size_small">
                Your email address will not be published. Required fields are
                {' '}
                <span data-css="form/form-label" className="form-label form-label_required">
                    marked
                </span>
            </p>
            <form
                id="comment-form"
                data-css="space text"
                className="space_size_n text text_size_small comment-form__form"
                action={COMMENTS_API_LINK}
                method="POST"
                onSubmit={handleSubmit}
            >
                {alertData.text && (
                    <div className="toast__comment-wrapper">
                        <AlertHn type={alertData.type}>
                            {alertData.text}
                        </AlertHn>
                    </div>
                )}
                <label htmlFor="comment-form-text">
                    <strong data-css="form/form-label" className="form-label form-label_required">
                        Comment
                    </strong>
                    <textarea
                        id="comment-form-text"
                        name="comment"
                        required
                        cols={45}
                        rows={8}
                        data-css="form/form-input space"
                        className="form-input space space_size_s"
                        minLength={MIN_LENGTH}
                        maxLength={MAX_LENGTH_CONTENT}
                        onInput={checkInputLength}
                    />
                </label>
                <div data-css="space" className="space space_size_n">
                    <div data-css="cells" className="cells cells_responsive">
                        <label
                            className="cells__item cells__item_width cells__item_width_2"
                            htmlFor="comment-form-name"
                        >
                            <strong
                                data-css="form/form-label"
                                className="form-label form-label_required"
                            >
                                Name
                            </strong>
                            <input
                                id="comment-form-name"
                                type="text"
                                name="name"
                                required
                                data-css="form/form-input space"
                                className="form-input space space_size_s"
                                minLength={MIN_LENGTH}
                                maxLength={MAX_LENGTH_NAME}
                                onInput={checkInputLength}
                            />
                        </label>
                        <label
                            className="cells__item cells__item_width cells__item_width_2"
                            htmlFor="comment-form-email"
                        >
                            <strong
                                data-css="form/form-label"
                                className="form-label form-label_required"
                            >
                                Email
                            </strong>
                            <input
                                id="comment-form-email"
                                type="email"
                                name="email"
                                required
                                data-css="form/form-input space"
                                className="form-input space space_size_s"
                                minLength={MIN_LENGTH}
                                maxLength={MAX_LENGTH_EMAIL}
                                onInput={checkInputLength}
                            />
                        </label>
                    </div>
                    <div data-css="space" className="space space_size_n">
                        <HCaptcha
                            sitekey={HCAPTCHA_SITE_KEY}
                            size="normal"
                            onVerify={hCaptchaHandlers.handleVerificationSuccess}
                            onError={hCaptchaHandlers.handleErrors}
                            onLoad={hCaptchaHandlers.handleCaptchaLoad}
                            onExpire={hCaptchaHandlers.handleTokenExpire}
                            ref={hCaptchaHandlers.captchaRef}
                        />
                    </div>
                    <label
                        data-css="space display"
                        className="space space_size_n display_block"
                        htmlFor="privacy_policy"
                    >
                        <strong className="form-label form-label_required">
                            Privacy Policy Agreement
                        </strong>
                        <span
                            data-css="space content display"
                            className="space space_size_s content display_block"
                        >
                            <input
                                id="privacy_policy"
                                name="privacy_policy"
                                required
                                type="checkbox"
                            />
                            &nbsp; I agree to the
                            {' '}
                            <a
                                data-css="link"
                                className="link"
                                href={COMMENTS_TERMS_LINK}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms &amp; Conditions
                            </a>
                            {' '}
                            and
                            {' '}
                            <a href={COMMENTS_PRIVACY_LINK} target="_blank" rel="noreferrer">
                                Privacy Policy
                            </a>
                            .
                        </span>
                    </label>
                </div>
                <div data-css="space" className="space space_size_l">
                    <button
                        data-css="button"
                        className="button"
                        type="submit"
                        disabled={submitStatusIsInProgress}
                    >
                        {submitStatusIsInProgress && (
                            <SVGIcon
                                className="button__icon"
                                iconId="mdi-loading"
                                width={20}
                                spin
                            />
                        )}
                        Post comment
                    </button>
                </div>
            </form>
        </div>
    );

    return useCustomTemplate ? customTemplate() : defaultTemplate();
};

export default CommentForm;
