import React, { JSX } from 'preact/compat';
import clsx from 'clsx';

type PropsT = {
    src: string;
    width: string | number;
    height: string | number;
    className?: string;
} & JSX.HTMLAttributes<HTMLImageElement>;

const LazyImage = ({ src, className, ...props }: PropsT) => (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
        className={clsx('lazyload', className)}
        src={`data:image/svg+xml;charset%3D'utf-8',%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20${props.width}%20${props.height}'%2F%3E`}
        data-src={src}
        {...props}
    />
);

export default LazyImage;
