import React, { JSX } from 'preact/compat';
import clsx from 'clsx';

import SVGIcon from './SVGIcon';

export enum AlertTypesE {
    Success = 'success',
    Error = 'error',
}

export type AlertTypeT = `${AlertTypesE}`;

type AlertPropsT = {
    type: `${AlertTypesE}`,
    onClose?: (e: MouseEvent) => void
} & JSX.HTMLAttributes<HTMLDivElement>;

const AlertHn = ({
    className,
    children,
    onClose,
    type = AlertTypesE.Success,
    ...props
}: AlertPropsT, ref) => (
    <div
        data-css="alert text"
        className={clsx('alert text text_size_small text_line-height_big', `alert_${type}`, className)}
        ref={ref}
        {...props}
    >
        {type === AlertTypesE.Success && (
            <SVGIcon
                className="alert__success"
                iconId="mdi-check-circle-outline"
                width={24}
            />
        )}

        {type === AlertTypesE.Error && (
            <SVGIcon
                className="alert__close"
                iconId="mdi-alert-circle-outline"
                width={24}
            />
        )}

        <div className="alert__message">
            {children}
        </div>

        {onClose && (
            <SVGIcon
                className="alert__close"
                iconId="mdi-close"
                width={20}
                onClick={onClose}
            />
        )}
    </div>
);

export default AlertHn;
