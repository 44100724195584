import React from 'preact/compat';
import clsx from 'clsx';

type PropsT = {
    path?: string,
    iconId: string,
    className?: string,
    spin?: boolean,
    width?: number | string,
    height?: number | string,
    onClick?: (e: MouseEvent) => void,
};

declare global {
    interface Window {
        svgIconsLoaded: string[];
    }
}

const appendSvgToDOM = async (path: string, iconId: string) => {
    const svgIconsWrapperId = 'svgIconsWrapper';
    let bodySvgIconsWrapper = document.querySelector(`#${svgIconsWrapperId}`) as SVGSVGElement;

    if (!window.svgIconsLoaded) {
        window.svgIconsLoaded = [path];
    } else if (!window.svgIconsLoaded.includes(path)) {
        window.svgIconsLoaded.push(path);
    } else {
        return;
    }

    if (!bodySvgIconsWrapper) {
        const bodyElement = document.querySelector('body');

        bodySvgIconsWrapper = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        bodySvgIconsWrapper.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        bodySvgIconsWrapper.setAttribute('style', 'display: none;');
        bodySvgIconsWrapper.id = svgIconsWrapperId;
        bodyElement?.appendChild(bodySvgIconsWrapper);
    }

    const svgResponse = await fetch(path, { method: 'GET' });

    if (svgResponse.status !== 200) {
        throw new Error(`Svg file with path: "${path}" not found`);
    }

    const svgContent = await svgResponse.text();

    if (!svgContent.includes(`id="${iconId}"`)) {
        throw new Error(`Svg icon "#${iconId}" not found in file: "${path}"`);
    }

    const svgTagOpenIndex = svgContent.indexOf('<svg ');
    const svgTagEndIndex = svgContent.indexOf('</svg>');

    if (svgTagOpenIndex === -1 || svgTagEndIndex === -1) {
        throw new Error(`Svg file "${path}" content in wrong format`);
    }

    const symbolEl = svgContent
        .substring(svgTagOpenIndex, svgTagEndIndex + 6)
        .replace('<svg ', '<symbol ')
        .replace('</svg>', '</symbol>');

    bodySvgIconsWrapper.innerHTML += symbolEl;
};

const SVGIcon = ({
    path,
    iconId,
    className,
    width,
    height,
    spin,
    ...props
}: PropsT) => {
    if (path && iconId) {
        appendSvgToDOM(path, iconId);
    }

    return (
        <svg
            data-css="svg-icon"
            className={clsx('svg-icon', {
                'svg-icon_spin': spin,
            }, className)}
            width={width}
            height={height || width}
            {...props}
        >
            <use xlinkHref={`#${iconId}`} />
        </svg>
    );
};

export default SVGIcon;
