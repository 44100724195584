import React, { useEffect, useState } from 'preact/compat';
import { CommentT } from './types';
import Comment from './components/Comment';
import CommentForm from './components/CommentForm';
import { useReplyForm } from './store';
import SVGIcon from '../components/SVGIcon';

type PropsT = {
    comments: CommentT[],
    isEnabled: boolean,
    useCustomTemplate?: boolean,
};

const COMMENTS_LIMIT = 10;

const Comments = ({
    comments,
    isEnabled = false,
    useCustomTemplate = false,
}: PropsT) => {
    const replyToCommentId = useReplyForm((store) => store.replyToCommentId);
    const [showMore, setShowMore] = useState(comments?.length > 10);
    // eslint-disable-next-line max-len
    const [commentsFormatted, setCommentsFormatted] = useState(comments ? comments.slice(0, COMMENTS_LIMIT) : null);

    useEffect(() => {
        if (!showMore) {
            setCommentsFormatted(comments);
        }
    }, [showMore]);

    const customTemplate = () => (
        <>
            {/* eslint-disable-next-line max-len */}
            {isEnabled && replyToCommentId === null && <CommentForm useCustomTemplate={useCustomTemplate} />}
            {isEnabled && commentsFormatted && commentsFormatted.length && (
                <>
                    <h3 className="heading heading_size_3 space space_size_l">
                        Comments
                    </h3>
                    <div className="space space_media-size space_direction_vertical" style={{ '--space': 'var(--space-vl)' }}>
                        {commentsFormatted && commentsFormatted.map((comment) => (
                            <Comment
                                key={comment.id}
                                comment={comment}
                                useCustomTemplate={useCustomTemplate}
                            />
                        ))}
                        {showMore && (
                            <>
                                <div className="comment__overlay" />
                                <button
                                    type="button"
                                    className="link link_color_accent-blue link_style_underlined text text_size_small comment__reply space space_size_n"
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    Show more comments
                                    <SVGIcon
                                        className="svg-icon comment__show-all-icon"
                                        iconId="mdi-arrow-down"
                                        width={14}
                                    />
                                </button>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );

    const defaultTemplate = () => (
        <>
            {isEnabled && commentsFormatted && commentsFormatted.length && (
                <>
                    <h2
                        data-css="heading"
                        className="heading heading_size_2"
                    >
                        Comments
                    </h2>
                    <div
                        data-css="space"
                        className="space"
                    >
                        {commentsFormatted && commentsFormatted.map((comment) => (
                            <Comment
                                key={comment.id}
                                comment={comment}
                                useCustomTemplate={useCustomTemplate}
                            />
                        ))}
                        {showMore && (
                            <div className="text_align_center">
                                <button type="button" className="button space space_size_n" onClick={() => setShowMore(!showMore)}>
                                    Show more comments
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            {/* eslint-disable-next-line max-len */}
            {isEnabled && replyToCommentId === null && <CommentForm useCustomTemplate={useCustomTemplate} />}
        </>
    );

    return (
        useCustomTemplate ? customTemplate() : defaultTemplate()
    );
};

export default Comments;
