import React from 'preact/compat';
import clsx from 'clsx';
import LazyImage from '../../components/LazyImage';
import { CommentT } from '../types';
import SVGIcon from '../../components/SVGIcon';
import { useReplyForm } from '../store';
import CommentForm from './CommentForm';

type PropsT = {
    comment: CommentT,
    isReply?: boolean,
    useCustomTemplate?: boolean,
};

const USER_DEFAULT_AVATAR = 'user-default-avatar.svg';

const Comment = ({
    comment,
    isReply = false,
    useCustomTemplate = false,
}: PropsT) => {
    const [
        needShowReplyForm,
        setReplyToCommentId,
    ] = useReplyForm((store) => [
        store.replyToCommentId === comment.id,
        store.setReplyToCommentId,
    ]);

    let authorImageCustomTemplate;
    let authorImageDefaultTemplate;

    if (comment.author.avatarImage.src.endsWith(USER_DEFAULT_AVATAR)) {
        authorImageCustomTemplate = (
            <SVGIcon
                className="comment__avatar image__gravatar"
                iconId="user-default-avatar"
                width={40}
            />
        );
    } else {
        authorImageCustomTemplate = (
            <LazyImage
                className="image image_lazy image__gravatar comment__avatar"
                src={comment.author.avatarImage.src}
                width={40}
                height={40}
                alt={comment.author.name}
            />
        );
    }

    if (comment.author.avatarImage.src.endsWith(USER_DEFAULT_AVATAR)) {
        authorImageDefaultTemplate = (
            <SVGIcon
                className="comment__avatar image__gravatar"
                iconId="user-default-avatar"
                width={52}
            />
        );
    } else {
        authorImageDefaultTemplate = (
            <LazyImage
                data-css="images/image"
                className="image image_lazy image__gravatar"
                src={comment.author.avatarImage.src}
                width={52}
                height={52}
                alt={comment.author.name}
            />
        );
    }

    const customTemplate = () => (
        <div
            className={clsx('comment', {
                'comment_is-reply': isReply,
            })}
        >
            <div className="comment__header">
                {authorImageCustomTemplate}
                <strong className="comment__author text_line-height_regular">
                    {comment.author.name}
                </strong>
                <div className="meta-item prefix text text_size_smallest">
                    <SVGIcon
                        className="prefix__item"
                        iconId="mdi-clock-outline"
                        width={16}
                    />
                    {comment.createdAtFormatted}
                </div>
            </div>
            <div className="text text_size_small">
                <div
                    className="space space_size_s content comment__text"
                    dangerouslySetInnerHTML={{
                        __html: comment.content,
                    }}
                />
                <div className="space space_size_s">
                    <button
                        type="button"
                        className="prefix link link_color_accent-blue link_style_underlined text text_size_small comment__reply"
                        style={{ '--prefix-icon-shift': '-2px' }}
                        onClick={() => setReplyToCommentId(comment.id)}
                    >
                        Reply
                    </button>
                </div>
            </div>
            {needShowReplyForm && <CommentForm useCustomTemplate={useCustomTemplate} />}
            <div className="comment__replies">
                {comment.replies && comment.replies.map((replyComment) => (
                    <Comment
                        key={replyComment.id}
                        comment={replyComment}
                        isReply
                        useCustomTemplate={useCustomTemplate}
                    />
                ))}
            </div>
        </div>
    );

    const defaultTemplate = () => (
        <div
            data-css="comment"
            className={clsx('comment', {
                'comment_is-reply': isReply,
            })}
        >
            <div
                data-css="cells media"
                className="cells"
            >
                <div className="cells__item media media_for_tablet media_for_desktop">
                    {authorImageDefaultTemplate}
                </div>
                <div
                    data-css="text"
                    className="cells__item cells__item_width_fill text text_size_small"
                >
                    <div
                        data-css="cells"
                        className="cells cells_align_center cells_space_s"
                    >
                        <strong
                            className="cells__item"
                        >
                            {comment.author.name}
                        </strong>
                        <div
                            data-css="post/meta-item prefix"
                            className="meta-item prefix cells__item"
                        >
                            <SVGIcon
                                className="prefix__item"
                                iconId="mdi-clock-outline"
                                width={14}
                            />
                            {comment.createdAtFormatted}
                        </div>
                    </div>
                    <div
                        data-css="space content"
                        className="space space_size_s content comment__text"
                        dangerouslySetInnerHTML={{
                            __html: comment.content,
                        }}
                    />
                    <div
                        data-css="space content"
                        className="space space_size_s"
                    >
                        <button
                            type="button"
                            data-css="prefix link text"
                            className="prefix link text text_size_smallest"
                            style={{ '--prefix-icon-shift': '-2px' }}
                            onClick={() => setReplyToCommentId(comment.id)}
                        >
                            <SVGIcon
                                className="prefix__item"
                                iconId="mdi-reply"
                                width={14}
                            />
                            Reply
                        </button>
                    </div>
                </div>
            </div>
            {needShowReplyForm && <CommentForm useCustomTemplate={useCustomTemplate} />}
            {comment.replies && comment.replies.map((replyComment) => (
                <Comment
                    key={replyComment.id}
                    comment={replyComment}
                    isReply
                    useCustomTemplate={useCustomTemplate}
                />
            ))}
        </div>
    );

    return (
        useCustomTemplate ? customTemplate() : defaultTemplate()
    );
};

export default React.memo(Comment);
