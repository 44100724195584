import create from 'zustand';

import { CommentT } from './types';

export type CommentsAreaT = {
    comments: CommentT[],
    isEnabled: boolean,
    useCustomTemplate: boolean
};

type ReplyFormT = {
    replyToCommentId?: string | null,
    setReplyToCommentId: (replyToCommentId?) => void
};

export const useReplyForm = create<ReplyFormT>((set) => ({
    replyToCommentId: null,
    setReplyToCommentId: (replyToCommentId?: string) => set({ replyToCommentId }),
}));
